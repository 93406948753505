/*
Based of https://github.com/vkbansal/react-contextmenu/blob/master/examples/react-contextmenu.css
 */

.react-contextmenu {
  background-color: #fefefe; /* color.white */
  background-clip: padding-box;
  border: 1px solid #e5e7eb; /* color.gray.200 */
  border-radius: 8px;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  color: #2a2e35; /* color.gray.700 */
  background: 0 0;
  border: 0;
  cursor: pointer;
  padding: 10px 6px;
  text-align: inherit;
  white-space: nowrap;
  margin-left: -1px;
  margin-right: -1px;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #03b4d9; /* color.secondary */
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: '▶';
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}
